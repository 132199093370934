import CreditQuantity from '@layouts/main/sections/toolbar/components/credit-quantity';
import {
  StyledBox,
  StyledStack,
} from '@layouts/main/sections/toolbar/mini/desktop/sections/credit-usage/styles';

export default function CreditUsage() {
  return (
    <StyledBox>
      <StyledStack direction="column" gap={2}>
        <CreditQuantity variant="miniDesktop" />
      </StyledStack>
    </StyledBox>
  );
}
