import { StyledComponent } from '@emotion/styled';
import {
  FormControlLabel,
  FormControlLabelProps,
  Switch,
  SwitchProps,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

type IProps = Omit<FormControlLabelProps, 'control'>;

interface Props extends IProps {
  name: string;
  SwitchComponent: typeof Switch | StyledComponent<SwitchProps>;
}

export default function RHFSwitch({
  name,
  SwitchComponent = Switch,
  onChange,
  defaultChecked,
  ...other
}: Props) {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <SwitchComponent
              {...field}
              onChange={(event, checked) => {
                field.onChange(checked);
                if (onChange) onChange(event, checked);
              }}
              checked={field.value}
            />
          )}
        />
      }
      {...other}
    />
  );
}
