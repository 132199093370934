import createFolderSchema from '@components/pages/knowledge-base/components/modals/create-folder/schema';
import useKnowledgeBaseData from '@components/pages/knowledge-base/hooks/useKnowledgeBaseData';
import useMutateFoldersByParentId from '@components/pages/knowledge-base/hooks/useMutateFoldersByParentId';
import FormProvider from '@components/react-hook-form/FormProvider';
import RHFTextField from '@components/react-hook-form/RHFTextField';
import { useTranslation } from '@desygner/ui-common-translation';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { queryClient } from '@providers/ReactQueryProvider';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

const defaultValues = {
  name: '',
};

export default function CreateFolderModal() {
  const { t } = useTranslation();

  const {
    handleToggleCreateFolderModal,
    isCreateFolderModalOpen,
    currentFolderId,
  } = useKnowledgeBaseData();

  const methods = useForm({
    resolver: yupResolver(createFolderSchema),
    defaultValues,
  });

  const { mutateFoldersAsync, isMutationFolderPending } =
    useMutateFoldersByParentId({
      parentFolderId: currentFolderId,
      onSuccess: () => {
        toast.success(
          t('response.success.createFolder', {
            defaultValue: 'You have successfully created a folder.',
          }),
        );
        queryClient.invalidateQueries({
          queryKey: ['folders'],
        });
        handleToggleCreateFolderModal();
      },
    });

  const {
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = methods;

  const isBusy = isMutationFolderPending || !isDirty;

  async function onSubmit(data: { name: string }) {
    await mutateFoldersAsync({
      name: data.name,
      workspaceId: '01918c48-915d-729c-abf2-807b79c8d75f',
    });
    reset();
  }

  return (
    <Modal
      onClose={handleToggleCreateFolderModal}
      open={isCreateFolderModalOpen}
    >
      <Card
        sx={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          width: '90%',
          maxWidth: 400,
          transform: 'translate(-50%, -50%)',
        }}
        component={Stack}
        gap={4}
      >
        <Typography variant="subtitle1">
          {t('page.knowledgeBase.modals.createFolder.title', {
            defaultValue: 'Create folder',
          })}
        </Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack gap={1}>
            <Typography variant="body2">
              {t('page.knowledgeBase.modals.createFolder.title', {
                defaultValue: 'Folder name',
              })}
            </Typography>
            <RHFTextField
              size="small"
              type="text"
              name="name"
              helperText={errors.name?.message}
              placeholder={t(
                'page.knowledgeBase.modals.createFolder.form.name.placeholder',
                {
                  defaultValue: 'Enter title',
                },
              )}
            />
          </Stack>
          <Stack gap={2} direction="row" sx={{ justifyContent: 'end', mt: 6 }}>
            <Button variant="outlined" onClick={handleToggleCreateFolderModal}>
              {t('page.knowledgeBase.modals.createFolder.form.buttons.cancel', {
                defaultValue: 'Cancel',
              })}
            </Button>
            <LoadingButton
              loading={isMutationFolderPending}
              onClick={handleSubmit(onSubmit)}
              disabled={isBusy}
              variant="contained"
            >
              {t(
                'page.knowledgeBase.modals.createFolder.form.buttons.submitButton',
                {
                  defaultValue: 'Save',
                },
              )}
            </LoadingButton>
          </Stack>
        </FormProvider>
      </Card>
    </Modal>
  );
}
