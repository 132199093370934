import isEmbedded from '@lib/loaderHelper';
import rawAppPaths from '@router/rawAppPaths';

type PathValueType = {
  pathname: string;
  title?: string;
  icon?: React.ReactNode;
};

const baseUrl = import.meta.env.VITE_KNOWZ_WEBSITE_URL;

export type PathsType = Record<string, PathValueType>;

const embeddedPaths = {
  commands: {
    pathname: 'create/knowz/commands',
    title: 'Commands',
  },
  editCommand: {
    pathname: 'create/knowz/editor/:id',
    title: 'Edit Command',
  },
  audit: {
    pathname: 'create/settings/audit',
    title: 'Audit',
  },
  status: {
    pathname: 'create/settings/status',
    title: 'Status',
  },
};

const standalonePaths = {
  connectors: {
    pathname: rawAppPaths.connectors,
    title: 'Connectors',
  },
  commands: {
    pathname: rawAppPaths.commands,
    title: 'Commands',
  },
  editCommand: {
    pathname: rawAppPaths.editCommand,
    title: 'Edit Command',
  },
  audit: {
    pathname: rawAppPaths.audit,
    title: 'Audit',
  },
  status: {
    pathname: rawAppPaths.status,
    title: 'Status',
  },
  home: {
    pathname: rawAppPaths.home,
  },
  search: {
    pathname: rawAppPaths.search,
    title: 'Search',
  },
  history: {
    pathname: rawAppPaths.history,
    title: 'history',
  },
  sideKick: {
    pathname: rawAppPaths.sideKick,
    title: 'Side-kick',
  },
  sideKickSession: {
    pathname: rawAppPaths.sideKickSession,
    title: 'Side-kick Session',
  },
  assistants: {
    pathname: rawAppPaths.assistants,
    title: 'Assistants',
  },
  assistantsSession: {
    pathname: rawAppPaths.assistantsSession,
    title: 'Assistants Session',
  },
  sso: {
    pathname: rawAppPaths.sso,
    title: 'Single Sign On',
  },
  editSso: {
    pathname: rawAppPaths.editSso,
    title: 'Edit Single Sign On',
  },
  AdminKnowledgeBase: {
    pathname: rawAppPaths.AdminKnowledgeBase,
    title: 'Admin Knowledge Base',
  },
  MemberKnowledgeBase: {
    pathname: rawAppPaths.MemberKnowledgeBase,
    title: 'Member Knowledge Base',
  },
  getTheApp: {
    pathname: rawAppPaths.getTheApp,
    title: 'Get the App',
  },
  //? Hubspot pages
  tos: {
    pathname: `${baseUrl}/knowz-search-terms-of-service`,
  },
  enterprise: {
    pathname: `${baseUrl}/enterprise`,
    title: 'Enterprise',
  },
  integration: {
    pathname: `${baseUrl}/connectors`,
    title: 'Integration',
  },
  solutions: {
    pathname: `${baseUrl}/solutions/support`,
    title: 'Solutions',
  },
  product: {
    pathname: `${baseUrl}/ai-enterprise-search-software`,
    title: 'Product',
  },
};

const paths: PathsType = isEmbedded ? embeddedPaths : standalonePaths;

export default paths;
