import ActionButton from '@components/pages/knowledge-base/components/action-button';
import LoadingFolderButton from '@components/pages/knowledge-base/components/loading-folder-button';
import CONFIG from '@components/pages/knowledge-base/components/table/config';
import Skeleton from '@components/pages/knowledge-base/components/table/loading-skeleton';
import {
  StyledMuiTableHeadCell,
  StyledPaper,
} from '@components/pages/knowledge-base/components/table/styles';
import TablePaginationActions from '@components/pages/knowledge-base/components/table/TablePaginationActions';
import useGetFolderPathFromQueryParam from '@components/pages/knowledge-base/hooks/useGetFolderPathFromQueryParam';
import useGetFoldersDataByIdLazily from '@components/pages/knowledge-base/hooks/useGetFoldersDataByIdLazily';
import useGetSourcesDataByFolderIdLazily from '@components/pages/knowledge-base/hooks/useGetSourcesDataByFolderIdLazily';
import useKnowledgeBaseData from '@components/pages/knowledge-base/hooks/useKnowledgeBaseData';
import {
  StyledCheckbox,
  StyledCheckboxIcon,
  StyledIntermittentCheckbox,
} from '@components/pages/knowledge-base/styles';
import { useTranslation } from '@desygner/ui-common-translation';
import useGetFolderDataByPathLazily from '@hooks/useGetFolderDataByPathLazily';
import useSearchParamsState from '@hooks/useSearchParamsState';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import MuiTable from '@mui/material/Table';
import MuiTableBody from '@mui/material/TableBody';
import MuiTableCell from '@mui/material/TableCell';
import MuiTableHead from '@mui/material/TableHead';
import MuiTablePagination from '@mui/material/TablePagination';
import MuiTableRow from '@mui/material/TableRow';
import MuiTableSortLabel from '@mui/material/TableSortLabel';
import { FolderType } from '@shared-types/folders';
import { SourceType } from '@shared-types/sources';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useCallback, useMemo } from 'react';

export default function Table() {
  const path = useGetFolderPathFromQueryParam();

  const { handleCurrentFolderId } = useKnowledgeBaseData();

  const { folderData, isFolderDataLoading, isFolderDataError } =
    useGetFolderDataByPathLazily(path);

  const { foldersData, isFoldersDataLoading, isFoldersDataError } =
    useGetFoldersDataByIdLazily(folderData?.id);

  const { sourcesData, isSourcesDataLoading, isSourcesDataError } =
    useGetSourcesDataByFolderIdLazily(folderData?.id);

  const { t } = useTranslation();

  const [, setSearchParams] = useSearchParamsState('path', '');

  const folderPathHandler = useCallback(
    (name: FolderType['name']) => {
      const concatenatedPath = path ? `${path}/${name}` : name;
      setSearchParams(concatenatedPath);
    },
    [path, setSearchParams],
  );

  const folderClickHandler = useCallback(
    (name: FolderType['name'], folderId: FolderType['id']) => {
      folderPathHandler(name);
      handleCurrentFolderId(folderId);
    },
    [folderPathHandler, handleCurrentFolderId],
  );

  const foldersAndSources = useMemo(
    () => [...(foldersData || []), ...(sourcesData || [])],
    [foldersData, sourcesData],
  );

  const areFoldersAndSourcesLoading =
    isFolderDataLoading || isFoldersDataLoading || isSourcesDataLoading;

  const areFoldersAndSourcesError =
    isFolderDataError || isFoldersDataError || isSourcesDataError;

  const columns = useMemo<ColumnDef<FolderType | SourceType>[]>(
    () => [
      {
        id: 'select',
        header: ({ table }) => {
          return (
            <Checkbox
              color="primary"
              icon={<StyledCheckboxIcon />}
              checkedIcon={<StyledCheckbox />}
              indeterminateIcon={<StyledIntermittentCheckbox />}
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          );
        },
        cell: ({ row }) => (
          <Checkbox
            icon={<StyledCheckboxIcon />}
            checkedIcon={<StyledCheckbox />}
            indeterminateIcon={<StyledIntermittentCheckbox />}
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            indeterminate={row.getIsSomeSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        ),
      },
      {
        header: 'Name',
        accessorKey: 'name',
        cell: ({ getValue, row }) => {
          const name = getValue<string>();
          const isItAFolder = 'breadcrumb' in row.original;
          const folderId = row.original.id;

          if (isItAFolder) {
            return (
              <Stack direction="row" gap={2}>
                <LoadingFolderButton
                  onClick={() => folderClickHandler(name, folderId)}
                >
                  {isItAFolder && <FolderOutlinedIcon />}
                  {name}
                </LoadingFolderButton>
              </Stack>
            );
          }

          //? then it is a file
          return name;
        },
      },
      {
        header: 'Format',
        accessorKey: 'fileType',
        cell: ({ row, getValue }) => {
          const fileType = getValue<string>();
          const isItAFolder = 'breadcrumb' in row.original;
          if (isItAFolder) return null;

          return <span>{fileType}</span>;
        },
      },
      {
        header: 'Location',
        accessorKey: 'location',
        cell: () => {
          return (
            <span>
              {t('comingSoon', {
                defaultValue: 'coming soon',
              })}
            </span>
          );
        },
      },
      {
        header: 'Sync Status',
        accessorKey: 'status',
        cell: () => {
          return (
            <span>
              {t('comingSoon', {
                defaultValue: 'coming soon',
              })}
            </span>
          );
        },
      },
      {
        header: 'Permission',
        accessorKey: 'permission',
        cell: () => {
          return (
            <span>
              {t('comingSoon', {
                defaultValue: 'coming soon',
              })}
            </span>
          );
        },
      },
      {
        header: 'Action',
        accessorKey: 'action',
        cell: () => (
          <Stack direction="row" gap={2}>
            <ActionButton>
              <SettingsOutlinedIcon />
            </ActionButton>
            <ActionButton>
              <DeleteOutlineOutlinedIcon />
            </ActionButton>
          </Stack>
        ),
      },
    ],
    [folderClickHandler, t],
  );

  const tableData = useReactTable({
    data: foldersAndSources,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    enableRowSelection: true,
  });

  const { pageSize, pageIndex } = tableData.getState().pagination;

  return (
    <>
      <StyledPaper>
        <MuiTable>
          <MuiTableHead>
            <MuiTableRow>
              {tableData?.getHeaderGroups().map((headerGroup) => {
                return headerGroup.headers.map((header) => {
                  const isItSelectCell = header.id === 'select';
                  const areTheySelectOrActionCell =
                    isItSelectCell || header.id === 'action';
                  return (
                    <StyledMuiTableHeadCell
                      key={header.id}
                      sx={{
                        ...(isItSelectCell && {
                          width: '10px',
                        }),
                      }}
                    >
                      <MuiTableSortLabel
                        active={!areTheySelectOrActionCell}
                        hideSortIcon={areTheySelectOrActionCell}
                        IconComponent={SwapVertIcon}
                        sx={{
                          width: '100%',
                          justifyContent: 'space-between',
                        }}
                      >
                        <span>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </span>
                      </MuiTableSortLabel>
                    </StyledMuiTableHeadCell>
                  );
                });
              })}
            </MuiTableRow>
          </MuiTableHead>
          <MuiTableBody>
            {areFoldersAndSourcesLoading ? (
              <Skeleton
                headItems={CONFIG.ADMIN_TABLE_HEADER}
                numberOfRows={7}
              />
            ) : (
              tableData.getRowModel().rows.map((row) => {
                return (
                  <MuiTableRow key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <MuiTableCell key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </MuiTableCell>
                      );
                    })}
                  </MuiTableRow>
                );
              })
            )}
          </MuiTableBody>
        </MuiTable>
      </StyledPaper>
      <MuiTablePagination
        rowsPerPageOptions={[
          5,
          10,
          25,
          { label: 'All', value: foldersAndSources.length },
        ]}
        component="div"
        count={tableData.getFilteredRowModel().rows.length}
        rowsPerPage={pageSize}
        page={pageIndex}
        slotProps={{
          select: {
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          },
        }}
        onPageChange={(_, page) => {
          tableData.setPageIndex(page);
        }}
        onRowsPerPageChange={(e) => {
          const size = e.target.value ? Number(e.target.value) : 5;
          tableData.setPageSize(size);
        }}
        ActionsComponent={TablePaginationActions}
      />
    </>
  );
}
