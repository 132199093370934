import ListboxComponent from '@components/autocomplete-with-carousel/listbox';
import { StyledStack } from '@components/pages/connectors/styles';
import { useTranslation } from '@desygner/ui-common-translation';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useState } from 'react';
import EllipsisTypography from '@components/ellipsis-typography';
import {
  StyledListItem,
  StyledListItemImage,
} from '@components/pages/sso/styles';
import Box from '@mui/material/Box';
import { useCreateOneSourceConfiguration } from '@hooks/useSourceConfigurations';

const ICON_SIZE = 84;

export default function Connectors() {
  const { t } = useTranslation();
  const [filter, setFilter] = useState('');
  const [authWindow, setAuthWindow] = useState<Window | null>(null);
  const { mutateAsync: mutateCreateOneSourceConfigurationAsync } =
    useCreateOneSourceConfiguration();

  const onMessage = useCallback(
    (event: MessageEvent) => {
      const { token, domain, store_key: storeKey } = event.data;

      if (storeKey) {
        // TODO
      } else if (token && domain) {
        mutateCreateOneSourceConfigurationAsync({
          handler: 'webrand',
          token,
          domain,
        });
      }

      authWindow && authWindow.close();
      window.removeEventListener('message', onMessage);
      setAuthWindow(null);
    },
    [authWindow, mutateCreateOneSourceConfigurationAsync],
  );

  useEffect(() => {
    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [onMessage]);

  const connect = useCallback((handler: string) => {
    if (handler === 'webrand') {
      const desygner = import.meta.env.VITE_DESYGNER_URL || '';

      setAuthWindow(
        window.open(
          `${desygner}/integration/knowz/setup`,
          'We Brand Authentication',
          'menubar=1,resizable=1,width=600,height=700',
        ),
      );
    } else {
      const auth = import.meta.env.VITE_KNOWZ_AUTH_URL || '';
      const alias = getSsoAliasForHandler(handler); // TODO

      setAuthWindow(
        window.open(
          `${auth}/connect/oauth2?alias=${alias}&process=store`,
          'Knowz Authentication',
          'menubar=1,resizable=1,width=900,height=550',
        ),
      );
    }
  }, []);

  return (
    <>
      <img
        src="https://assets.knowz.com/assets/svg/side-kick.svg"
        alt="knowz Connectors"
        height="93"
      />

      <Typography component="h1" variant="h3">
        {t('layout.page.connectors.hero.title', {
          defaultValue: 'Bring your knowledge',
        })}
      </Typography>

      <StyledStack gap={5}>
        <TextField
          label={t('page.connectors.search.title', {
            defaultValue: 'Find Connectors',
          })}
          onChange={(event) =>
            setFilter(event.target.value.toLocaleLowerCase())
          }
          variant="outlined"
        />

        <ListboxComponent>
          <StyledListItem
            components={{ Root: 'div' }}
            onClick={(event) => connect('webrand')}
          >
            <StyledListItemImage
              sx={{ mt: 3 }}
              src="https://s3.amazonaws.com/virginia.webrand.com/virginia/company/5899/logo/original/qqwwqryrrwyqrry/group-7.svg"
              loading="lazy"
              height={ICON_SIZE}
            />

            <Box sx={{ p: 3 }}>
              <EllipsisTypography
                variant="subtitle2"
                component="h2"
                lineClamp={2}
              >
                We Brand
              </EllipsisTypography>
            </Box>
          </StyledListItem>
        </ListboxComponent>
      </StyledStack>
    </>
  );
}
