import { MinimalFolderType } from '@components/pages/knowledge-base/admin/types';
import { sources } from '@lib/agent';
import { useQuery } from '@tanstack/react-query';

export default function useGetSourcesDataByFolderIdLazily(
  folderId: MinimalFolderType['folderId'] = null,
) {
  const {
    data: sourcesData,
    isLoading: isSourcesDataLoading,
    isError: isSourcesDataError,
  } = useQuery({
    queryKey: ['sources', folderId],
    queryFn: async () => {
      try {
        const { data } = await sources.getAll({
          folder: folderId,
          workspaceId: '01918c48-915d-729c-abf2-807b79c8d75f',
        });

        return data;
      } catch (error: unknown) {
        throw new Error(`An error occurred while fetching sources: ${error}`);
      }
    },
  });

  return {
    sourcesData,
    isSourcesDataLoading,
    isSourcesDataError,
  };
}
