import useGetCreditAsPercentage from '@hooks/useGetCreditAsPercentage';
import {
  StyledExpandedSlider,
  StyledMiniDesktopSlider,
  StyledMiniMobileSlider,
} from '@layouts/main/sections/toolbar/components/credit-quantity/styles';
import { CreditQuantityType } from '@layouts/main/sections/toolbar/components/credit-quantity/types';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

const STAR_SIZE = 20;

const SLIDERS = {
  expanded: StyledExpandedSlider,
  miniDesktop: StyledMiniDesktopSlider,
  miniMobile: StyledMiniMobileSlider,
};

export default function CreditQuantity({ variant }: CreditQuantityType) {
  const { creditsData, isCreditLoading } = useGetCreditAsPercentage();
  const CalculatedSlider = SLIDERS[variant];

  return (
    <>
      <img
        src="https://assets.knowz.com/assets/images/star.png"
        alt="star"
        width={STAR_SIZE}
        height={STAR_SIZE}
      />
      <CalculatedSlider
        value={creditsData || 0}
        orientation={variant === 'miniDesktop' ? 'vertical' : 'horizontal'}
      />
      {isCreditLoading || typeof creditsData == 'undefined' ? (
        <Skeleton variant="rectangular" width={35} height={20} />
      ) : (
        <Typography variant="overline" sx={{ fontWeight: 400 }}>
          {creditsData < 0 ? 0 : Math.round(Number(creditsData))}%
        </Typography>
      )}
    </>
  );
}
