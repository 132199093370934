import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SearchResultState = {
  askAIErrored: {
    status: number;
  } | null;
  advancedSearch: boolean;
};

const initialState: SearchResultState = {
  askAIErrored: null,
  advancedSearch: false,
};

const searchResult = createSlice({
  name: 'searchResult',
  initialState,
  reducers: {
    searchAdvanced: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        advancedSearch: action.payload,
      };
    },
    askAIErrored: (
      state,
      action: PayloadAction<SearchResultState['askAIErrored']>,
    ) => {
      if (action.payload == null) return state;

      return {
        ...state,
        askAIErrored: { status: action.payload.status },
      };
    },
  },
});

export const { askAIErrored, searchAdvanced } = searchResult.actions;

export default searchResult.reducer;
