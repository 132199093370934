import { useTranslation } from '@desygner/ui-common-translation';
import { folders } from '@lib/agent';
import { queryClient } from '@providers/ReactQueryProvider';
import paths from '@router/paths';
import { useQuery } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function useGetFolderDataByPathLazily(path: string | null) {
  const navigateTo = useNavigate();
  const { t } = useTranslation();
  const isItRootFolder = path === null || path === '';

  const {
    data: folderData,
    isLoading: isFolderDataLoading,
    isError: isFolderDataError,
  } = useQuery({
    queryKey: ['foldersByPath', path],
    queryFn: async () => {
      try {
        if (isItRootFolder) {
          return null;
        }
        const { data } = await folders.getOneByPath({
          path: normalizePath(decodeURIComponent(path!)),
          workspaceId: '01918c48-915d-729c-abf2-807b79c8d75f', //TODO: we need to make this dynamic,
        });
        return data;
      } catch (error) {
        if (isAxiosError(error) && error.response?.status === 404) {
          navigateTo(paths.AdminKnowledgeBase.pathname);
          toast.error(
            t('response.errors.code.404', {
              defaultValue: 'Resource not found.',
            }),
          );
          queryClient.removeQueries({
            queryKey: ['foldersByPath', path],
          });
          return;
        }
        throw new Error(`An error occurred while fetching folders: ${error}`);
      }
    },
  });

  return {
    folderData,
    isFolderDataLoading,
    isFolderDataError,
  };
}

function normalizePath(path: string) {
  return path.split(' ').join('-');
}
