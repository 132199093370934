/* eslint-disable import/prefer-default-export */
import requests from '@lib/agent/requests';
import axiosApiInstance from '@lib/agent/services/api/axiosInstance';
import { SourceRequestType, SourceType } from '@shared-types/sources';

export const sources = {
  getAll: ({ workspaceId, folder }: SourceRequestType) =>
    requests(axiosApiInstance).get<SourceType[]>(
      `/v1/entities/sources?folder=${folder}`,
      {
        headers: {
          'Knowz-Reading-Owners': workspaceId,
        },
      },
    ),
};
