import FormProvider from '@components/react-hook-form/FormProvider';
import RHFSwitch from '@components/react-hook-form/RHFSwitch';
import RHFTextField from '@components/react-hook-form/RHFTextField';
import searchFormSchema from '@components/search-form/schema';
import {
  IOSSwitch,
  StyledSearchButton,
  StyledSearchFormWrapper,
  StyledTooltip,
} from '@components/search-form/styles';
import TooltipContent from '@components/search-form/tooltip-content';
import { useTranslation } from '@desygner/ui-common-translation';
import { yupResolver } from '@hookform/resolvers/yup';
import CameraOutlinedIcon from '@mui/icons-material/CameraOutlined';
import SearchIcon from '@mui/icons-material/Search';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { alpha, SxProps, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { searchAdvanced } from '@state/slices/search-result';
import { RootType } from '@state/store';
import { useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

type SearchFormValuesType = {
  name: string;
  advanced: boolean;
};

type SearchFormType = {
  onSubmit: SubmitHandler<SearchFormValuesType>;
  sx?: SxProps;
};

export default function SearchForm({ onSubmit, sx }: SearchFormType) {
  const { t } = useTranslation();

  const theme = useTheme();

  const dispatch = useDispatch();

  const advancedSearch = useSelector(
    (state: RootType) => state.searchResult.advancedSearch,
  );

  function handleAdvancedSearch(
    _event: React.SyntheticEvent,
    checked: boolean,
  ) {
    dispatch(searchAdvanced(checked));
  }

  const defaultValues: SearchFormValuesType = useMemo(
    () => ({
      name: '',
      advanced: advancedSearch,
    }),
    [advancedSearch],
  );

  const methods = useForm({
    resolver: yupResolver(searchFormSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isDirty },
  } = methods;

  return (
    <StyledSearchFormWrapper sx={sx} className="semantic-search-form">
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack
          direction="row"
          gap={2}
          sx={{
            alignItems: 'center',
            mb: 2.5,
            justifyContent: 'space-between',
          }}
        >
          <Tooltip
            title={t('comingSoon', {
              defaultValue: 'Coming soon',
            })}
            placement="top"
          >
            <Stack direction="row">
              <IconButton disabled>
                <UploadFileOutlinedIcon />
              </IconButton>
              <IconButton disabled>
                <CameraOutlinedIcon />
              </IconButton>
            </Stack>
          </Tooltip>
          <Stack direction="row" gap={2} sx={{ alignItems: 'center' }}>
            <StyledTooltip placement="top" title={<TooltipContent />}>
              <Box>
                <RHFSwitch
                  SwitchComponent={IOSSwitch}
                  name="advanced"
                  label={
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ ml: 2 }}
                    >
                      {t('page.home.hero.search.advancedSearch', {
                        defaultValue: 'Genius mode',
                      })}
                    </Typography>
                  }
                  onChange={handleAdvancedSearch}
                  checked={advancedSearch}
                  defaultChecked={advancedSearch}
                />
              </Box>
            </StyledTooltip>
          </Stack>
        </Stack>
        <RHFTextField
          role="search"
          type="text"
          name="name"
          autoComplete="off"
          helperText={undefined} //? we don't want to show the error message
          InputProps={{
            endAdornment: (
              <StyledSearchButton
                variant="outlined"
                color="inherit"
                endIcon={<SearchIcon />}
                onClick={handleSubmit(onSubmit)}
                disabled={!isDirty}
                disableRipple
                disableTouchRipple
                disableFocusRipple
              >
                <Box
                  component="span"
                  sx={{ display: { xs: 'none', md: 'block' } }}
                >
                  {t('page.home.hero.search.button', {
                    defaultValue: 'Uncover secrets',
                  })}
                </Box>
              </StyledSearchButton>
            ),
            placeholder: t('page.home.hero.search.placeholder', {
              defaultValue: 'Search for...',
            }),
            sx: {
              borderRadius: theme.shape.borderRadius * 10,
              pr: 0,
              pl: theme.spacing(2),
              height: theme.spacing(12),
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? alpha(theme.palette.common.white, 0.05)
                  : alpha(theme.palette.common.black, 0.06),
            },
          }}
        />
      </FormProvider>
    </StyledSearchFormWrapper>
  );
}
