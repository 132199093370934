import StyledFeatureButton from '@components/search-form/tooltip-content/styles';
import { useTranslation } from '@desygner/ui-common-translation';
import useAuth from '@hooks/useAuth';
import useSearchParamsState from '@hooks/useSearchParamsState';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const STAR_SIZE = 20;

export default function TooltipContent() {
  const { t } = useTranslation();

  const [, setSearchParams] = useSearchParamsState('action', '');

  const { me } = useAuth();

  return (
    <Stack gap={2}>
      <Stack direction="row" gap={2}>
        <AutoAwesomeIcon />
        <Typography variant="body1">
          {t('page.home.hero.search.genius.title', {
            defaultValue: 'Genius mode',
          })}
        </Typography>
      </Stack>
      <Typography variant="caption">
        {t('page.home.hero.search.genius.subtitle', {
          defaultValue:
            'A tailored search experience that takes a little extra time to deliver highly relevant and refined responses, providing you with the best-suited resources for your queries.',
        })}
      </Typography>
      {me?.paid ? null : (
        <StyledFeatureButton onClick={() => setSearchParams('upgrade-account')}>
          <img
            src="https://assets.knowz.com/assets/svg/single-start.svg"
            alt="star"
            width={STAR_SIZE}
            height={STAR_SIZE}
          />
          {t('page.home.hero.search.genius.button', {
            defaultValue: 'Premium Feature',
          })}
        </StyledFeatureButton>
      )}
    </Stack>
  );
}
