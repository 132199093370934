/* eslint-disable no-case-declarations */
import { queryClient } from '@providers/ReactQueryProvider';
import { PayloadAction } from '@reduxjs/toolkit';
import router from '@router/index';
import askAIFetcher from '@state/middleware/utils/askAiFetcher';
import subscribeToThread from '@state/middleware/utils/subscriber';
import { askAIErrored } from '@state/slices/search-result';
import { WSLoaded } from '@state/slices/web-socket';
import { RootType } from '@state/store';
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';
import { Action, Middleware } from 'redux';

const searchResultMiddleware: Middleware = ({ dispatch, getState }) => {
  let subscribeThread: string | null = null;

  return (next) => (action) => {
    const { type } = action as Action<
      'searchResult/askAIEmitted' | 'searchResult/streaming/finished'
    >;
    switch (type) {
      case 'searchResult/askAIEmitted':
        dispatch(WSLoaded(true));
        const { payload } = action as PayloadAction<{
          threadId: string;
          prompt: string;
          latlng: number[] | null;
          advanced: boolean;
        }>;

        if (payload == null) return;

        (async function askAI() {
          try {
            subscribeThread = subscribeToThread(
              payload.threadId,
              subscribeThread,
            );

            await askAIFetcher({
              type: 'search',
              thread: payload.threadId,
              prompt: payload.prompt,
              advanced: payload.advanced,
              context: {
                lat_lng: payload.latlng || [0, 0],
                language: navigator.language,
                time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              },
            });
          } catch (error) {
            if (isAxiosError(error)) {
              dispatch(askAIErrored({ status: error.response!.status }));
              dispatch(WSLoaded(false));
              if (error.response!.status === 429) {
                toast.error('Too many requests. Please try again later.'); //TODO: we can not use useTranslation here because we are in a react component
                return;
              }
              if (error.response!.status === 402) {
                queryClient.invalidateQueries({
                  queryKey: ['credits'],
                });
                return router.navigate('/');
              }
              toast.error(
                'An unexpected error occurred. Please try again later.',
              );
              console.error('Error asking AI', error);
              return;
            }
          }
        })();
        break;
      case 'searchResult/streaming/finished':
        const threadId = new URL(window.location.href).searchParams.get('t');
        const WSResponse = (getState() as RootType).websocket.WSResponse;

        //? revalidate credit usage
        queryClient.invalidateQueries({ queryKey: ['credits'] });

        //? store data in a cache
        if (threadId == null || subscribeThread !== threadId) return;

        queryClient.setQueryData(
          ['historyBlocks', threadId],
          (oldData: any) => {
            const newData =
              oldData == null
                ? { threadData: {}, historyBlocksData: [] }
                : oldData;
            const { threadData, historyBlocksData } = newData;
            const answer = WSResponse.searchResult.layout.find(
              (block) => block?.type === 'answer',
            );
            const photos = WSResponse.searchResult.layout.find(
              (block) => block?.type === 'photos',
            );
            const infos = WSResponse.searchResult.layout.find(
              (block) => block?.type === 'info',
            );
            const links = WSResponse.searchResult.layout.find(
              (block) => block?.type === 'links',
            );
            const followups = WSResponse.searchResult.followups;
            return {
              threadData,
              historyBlocksData: [
                ...historyBlocksData,
                {
                  followups,
                  layout: {
                    userQuery: window.history.state.prompt,
                    answer: {
                      response: answer?.text,
                    },
                    info: [
                      {
                        response: infos?.text,
                      },
                    ],
                    photos: {
                      photos: photos?.photos || [],
                    },
                    links: links?.links || [],
                  },
                  tos: WSResponse.searchResult.TOS,
                },
              ],
            };
          },
        );
        break;
    }

    next(action);
  };
};

export default searchResultMiddleware;
