import { styled } from '@mui/material';
import Slider from '@mui/material/Slider';

export const SharedStyledSlide = styled(Slider)(({ theme }) => ({
  cursor: 'default',
  padding: 0,
  '.MuiSlider-thumb': {
    display: 'none',
  },
  '& .MuiSlider-rail': {
    opacity: 1,
    backgroundColor: theme.palette.grey[100],
  },
  '& .MuiSlider-track': {
    background: theme.palette.gradients('to right').primary,
    border: 0,
  },
  [theme.breakpoints.up('md')]: {
    '& .MuiSlider-track': {
      background: theme.palette.gradients('to top').primary,
      border: 0,
    },
  },
}));

export const StyledExpandedSlider = styled(SharedStyledSlide)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    height: theme.spacing(2),
    width: '100%',
  },
}));

export const StyledMiniDesktopSlider = styled(SharedStyledSlide)(
  ({ theme }) => ({
    height: theme.spacing(2),
    width: theme.spacing(25),
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(15),
      width: theme.spacing(2),
    },
  }),
);

export const StyledMiniMobileSlider = styled(SharedStyledSlide)(
  ({ theme }) => ({
    height: theme.spacing(2),
    width: theme.spacing(25),
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(15),
      width: theme.spacing(2),
    },
  }),
);
