import KnowledgeBaseProvider from '@components/pages/knowledge-base/providers/KnowledgeBaseProvider';
import { StyledStack } from '@components/pages/knowledge-base/styles';

export default function AdminKnowledgeBase() {
  return (
    <KnowledgeBaseProvider>
      <StyledStack gap={6}>
        <KnowledgeBaseProvider.CreateFolderModal />
        <KnowledgeBaseProvider.AddKnowledgeBaseModal />
        <KnowledgeBaseProvider.Header />
        <KnowledgeBaseProvider.Table />
      </StyledStack>
    </KnowledgeBaseProvider>
  );
}
