import CONFIG from '@components/pages/knowledge-base/components/table/config';
import Skeleton from '@mui/material/Skeleton';
import MuiTableCell from '@mui/material/TableCell';
import MuiTableRow from '@mui/material/TableRow';

type Props = {
  headItems: typeof CONFIG.ADMIN_TABLE_HEADER;
  numberOfRows: number;
};

export default function LoadingSkeleton({ headItems, numberOfRows }: Props) {
  return Array.from({ length: numberOfRows }, (_, index) => (
    <MuiTableRow key={index}>
      {headItems.map((item) => (
        <MuiTableCell key={item}>
          <Skeleton
            key={item}
            variant="text"
            sx={{
              height: 48,
            }}
          />
        </MuiTableCell>
      ))}
    </MuiTableRow>
  ));
}
