import getRefreshToken from '@lib/auth/email';
import { login, signUp } from '@lib/auth/recaptcha';
import {
  checkUserAuthType,
  getDecodedToken,
  isValidToken,
  setSession,
  sendTokenToExtension,
} from '@lib/jwt';
import { RecaptchaRefType, UserType } from '@shared-types/auth';
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReCAPTCHA, { ReCAPTCHAProps } from 'react-google-recaptcha';

const SITE_KEY = String(import.meta.env.VITE_RECAPTCHA_KEY);

const RECAPTCHA_CONFIG: ReCAPTCHAProps = {
  sitekey: SITE_KEY,
  size: 'invisible',
  theme: 'dark',
};

const THRESHOLD_TIME_TO_REFRESH_TOKEN = 120_000;

export const AuthContext = createContext<{
  me: UserType | null;
  isUserWaitingForUpgrade: boolean;
  handleIsUserWaitingForUpgrade: (value: boolean) => void;
  isUserAuthenticated: boolean;
  handleUpdateMe: (token: string) => void;
  handleAttemptedToLogin: (value: boolean) => void;
  attemptedToLogin: boolean | null;
  handleIsUserAuthenticated: (value: boolean) => void;
} | null>(null);

export default function AuthProvider({ children }: React.PropsWithChildren) {
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  const [me, setMe] = useState<UserType | null>(null);
  const [isUserWaitingForUpgrade, setIsUserWaitingForUpgrade] = useState(false);
  const [attemptedToLogin, setAttemptedToLogin] = useState<boolean | null>(
    null,
  );

  const intervalIDRef = useRef<NodeJS.Timeout>();

  function handleIsUserWaitingForUpgrade(value: boolean) {
    setIsUserWaitingForUpgrade(value);
  }

  function handleUpdateMe(token: string) {
    const me = getDecodedToken(token);
    setMe(me);
  }

  function handleAttemptedToLogin(value: boolean) {
    setAttemptedToLogin(value);
  }

  function handleIsUserAuthenticated(value: boolean) {
    setIsUserAuthenticated(value);
  }

  useEffect(() => {
    if (me == null) return;
    const currentTimeInMS = Date.now();
    const tokenExpirationInMS = me.exp * 1000;
    const differenceInMS = tokenExpirationInMS - currentTimeInMS;
    const timeToRefreshToken = differenceInMS - THRESHOLD_TIME_TO_REFRESH_TOKEN;
    if (timeToRefreshToken <= 0) return;

    async function refreshTokenIfNeeded() {
      try {
        const response = await getRefreshToken();
        if (response?.data.status === 'Ok') {
          const token = response.data.token;
          setSession(token);
          handleUpdateMe(token);
        }
      } catch (error) {
        console.error('Failed to refresh token:', error);
      }
    }

    intervalIDRef.current = setInterval(
      refreshTokenIfNeeded,
      timeToRefreshToken,
    );

    return () => clearInterval(intervalIDRef.current);
  }, [me]);

  const initialize = useCallback(
    (node: RecaptchaRefType) => {
      (async function onLoad() {
        try {
          //? on user entering the site, check if the user is authenticated with which method
          const existingToken = window.localStorage.getItem('token')!;

          //? if there is not a token in local storage sign up and login the user with web_fingerprint method
          const authType = checkUserAuthType(existingToken);

          const isTokenValid = existingToken && isValidToken(existingToken);

          //? if user is authenticated with all methods rather than web_fingerprint method
          if (authType === 'oauth2' || authType === 'email') {
            if (isTokenValid) {
              setSession(existingToken);
              setIsUserAuthenticated(true);
              handleUpdateMe(existingToken);
              sendTokenToExtension(existingToken);
              return;
            }

            //? if user token is expired and user has not attempted to login yet
            if (!isTokenValid && attemptedToLogin == null) {
              setAttemptedToLogin(false);
              return;
            }
          }

          //? if user has not attempted to login yet we wait for the user to attempt to login before we proceed with web_fingerprint method
          if (attemptedToLogin === false) return;

          if (authType === 'web_fingerprint') {
            const isTokenValidForWebFingerprintMethod =
              isTokenValid && authType === 'web_fingerprint';

            if (isTokenValidForWebFingerprintMethod) {
              setSession(existingToken);
              setIsUserAuthenticated(true);
              handleUpdateMe(existingToken);
              sendTokenToExtension(existingToken);
              return;
            }
          }
          //? user is not authenticated with email or web_fingerprint method yet and it is a fresh user so we go with webfingerPrint method
          const recaptchaToken = await node?.executeAsync();
          //? or user has cleared the local storage and we need to login the user again
          const response = await signUp(recaptchaToken!);

          if (response && response.data.status === 'Ok') {
            const webFingerToken = response.data.token;
            await login();
            setSession(webFingerToken);
            handleUpdateMe(webFingerToken);
            setIsUserAuthenticated(true);
            sendTokenToExtension(webFingerToken);
          }
        } catch (err: unknown) {
          setIsUserAuthenticated(false);
          console.error(err);
        }
      })();
    },
    [attemptedToLogin],
  );

  const value = useMemo(
    () => ({
      me,
      handleUpdateMe,
      isUserWaitingForUpgrade,
      handleIsUserWaitingForUpgrade,
      isUserAuthenticated,
      handleAttemptedToLogin,
      attemptedToLogin,
      handleIsUserAuthenticated,
    }),
    [attemptedToLogin, isUserAuthenticated, isUserWaitingForUpgrade, me],
  );

  return (
    <AuthContext.Provider value={value}>
      {!isUserAuthenticated && (
        <ReCAPTCHA {...RECAPTCHA_CONFIG} ref={initialize} />
      )}
      {children}
    </AuthContext.Provider>
  );
}
