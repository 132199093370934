import {
  StyledCenteredContent,
  StyledViewportHeightStack,
} from '@components/pages/styles';
import Container from '@mui/material/Container';
import { ConfirmProvider } from 'material-ui-confirm';
import Connectors from '@components/pages/connectors/Connectors';

export default function ConnectorsIndex() {
  return (
    <ConfirmProvider
      defaultOptions={{
        confirmationButtonProps: {
          variant: 'contained',
        },
        cancellationButtonProps: {
          variant: 'outlined',
        },
        // allowClose: false,
      }}
    >
      <Container>
        <StyledViewportHeightStack>
          <StyledCenteredContent gap={8}>
            <Connectors />
          </StyledCenteredContent>
        </StyledViewportHeightStack>
      </Container>
    </ConfirmProvider>
  );
}
