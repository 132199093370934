import requests from '@lib/agent/requests';
import axiosApiInstance from '@lib/agent/services/api/axiosInstance';
import { FolderBodyType, FolderType } from '@shared-types/folders';

export const folders = {
  getAll: ({
    workspaceId,
    parent,
  }: Pick<FolderBodyType, 'parent' | 'workspaceId'>) => {
    const shouldPassHeaders = Boolean(workspaceId);
    if (shouldPassHeaders) {
      return requests(axiosApiInstance).get<FolderType[]>(
        `/v1/entities/folders?parent=${parent}`,
        {
          headers: {
            'Knowz-Reading-Owners': workspaceId,
          },
        },
      );
    }

    return requests(axiosApiInstance).get<FolderType[]>('/v1/entities/folders');
  },
  createOne: ({ workspaceId, ...body }: FolderBodyType) => {
    const shouldPassHeaders = Boolean(workspaceId);
    if (shouldPassHeaders) {
      return requests(axiosApiInstance).post('/v1/entities/folders', body, {
        headers: {
          'Knowz-Creation-Owner': workspaceId,
        },
      });
    }

    return requests(axiosApiInstance).post('/v1/entities/folders', body);
  },
  getOneByPath: ({
    workspaceId,
    path,
  }: {
    workspaceId: string;
    path: string;
  }) =>
    requests(axiosApiInstance).get<FolderType>(
      `/v1/entities/folders/path/${path}`,
      {
        headers: {
          'Knowz-Reading-Owners': workspaceId,
        },
      },
    ),
  getOneById: ({
    folderId,
    workspaceId,
  }: {
    folderId: string;
    workspaceId: string;
  }) =>
    requests(axiosApiInstance).get<FolderType>(
      `/v1/entities/folders/${folderId}`,
      {
        headers: {
          'Knowz-Reading-Owners': workspaceId,
        },
      },
    ),
};

export default folders;
