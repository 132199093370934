import useGetOneThread from '@components/pages/hooks/useGetOneThread';
import { assistants } from '@lib/agent/endpoints/assistants';
import { useQuery } from '@tanstack/react-query';

export default function useGetOneAssistant() {
  const { data: oneThreadData, isLoading: isOneThreadDataLoading } =
    useGetOneThread();

  return useQuery({
    queryKey: ['assistant'],
    queryFn: async () => {
      try {
        const { data: assistantsData } = await assistants.getOne(
          String(oneThreadData?.botId),
        );
        return assistantsData;
      } catch (error) {
        console.error(error);
      }
    },
    enabled: !isOneThreadDataLoading,
  });
}
