import { MinimalFolderType } from '@components/pages/knowledge-base/admin/types';
import { folders } from '@lib/agent';
import { useQuery } from '@tanstack/react-query';

export default function useGetFolderByIdLazily(
  folderId: MinimalFolderType['folderId'] = null,
) {
  const { data: folderData } = useQuery({
    queryKey: ['folder', folderId],
    queryFn: async () => {
      try {
        const { data } = await folders.getOneById({
          folderId: String(folderId),
          workspaceId: '01918c48-915d-729c-abf2-807b79c8d75f', //TODO: we need to make this dynamic,
        });

        return data;
      } catch (error: unknown) {
        throw new Error(`An error occurred while fetching folders: ${error}`);
      }
    },
    enabled: !!folderId,
  });

  return {
    folderData,
  };
}
