/**
 * These paths are used by the router to navigate to different parts of the app
 * and also exported to be used at infrastructure level for routing.
 **/
const rawAppPaths = {
  connectors: '/connectors',
  commands: '/commands',
  editCommand: '/commands/editor/:id',
  audit: '/settings/audit',
  status: '/settings/status',
  assistants: '/assistants',
  assistantsSession: '/assistants/session',
  home: '/',
  search: '/search',
  history: '/history',
  sideKick: '/side-kick',
  sideKickSession: '/side-kick/session',
  sso: '/settings/sso',
  editSso: '/settings/sso/:id',
  AdminKnowledgeBase: '/knowledge-base/admin',
  MemberKnowledgeBase: '/knowledge-base/member',
  adminKnowledgeBase: '/knowledge-base/admin',
  memberKnowledgeBase: '/knowledge-base/member',
  workspaces: '/workspaces',
  getTheApp: '/get-the-app',
};

export default rawAppPaths;
