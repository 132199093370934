import useLocalStorage from '@hooks/useLocalStorage';
import Content from '@layouts/main/sections/toolbar/components/content';
import ExpandedToolbar from '@layouts/main/sections/toolbar/expanded';
import DesktopMiniToolbar from '@layouts/main/sections/toolbar/mini/desktop';
import MobileToolbar from '@layouts/main/sections/toolbar/mini/mobile';
import { createContext, useCallback, useMemo, useState } from 'react';

type ToolbarContextType = {
  handleToggleToolbar: VoidFunction;
  toolbarExpansionStatus: 'on' | 'off';
  handleDisplayExpandedButton: VoidFunction;
  handleHideExpandedButton: VoidFunction;
  isExpandedButtonDisplayed: boolean;
};

export const ToolbarContext = createContext<ToolbarContextType | null>(null);

export default function ToolbarProvider({ children }: React.PropsWithChildren) {
  const { value: toolbarExpansionStatus, setValue: setToolbarExpansionStatus } =
    useLocalStorage<'on' | 'off'>('toolbarExtended', 'off');

  const [isExpandedButtonDisplayed, setIsExpandedButtonDisplayed] =
    useState(false);

  const handleToggleToolbar = useCallback(() => {
    if (toolbarExpansionStatus === 'on') {
      setToolbarExpansionStatus('off');
    } else {
      setToolbarExpansionStatus('on');
    }
    setIsExpandedButtonDisplayed((prev) => !prev);
  }, [toolbarExpansionStatus, setToolbarExpansionStatus]);

  const handleDisplayExpandedButton = useCallback(() => {
    setIsExpandedButtonDisplayed(true);
  }, []);

  const handleHideExpandedButton = useCallback(() => {
    setIsExpandedButtonDisplayed(false);
  }, []);

  const value = useMemo(
    () => ({
      handleToggleToolbar,
      toolbarExpansionStatus,
      handleDisplayExpandedButton,
      handleHideExpandedButton,
      isExpandedButtonDisplayed,
    }),
    [
      handleDisplayExpandedButton,
      handleToggleToolbar,
      toolbarExpansionStatus,
      isExpandedButtonDisplayed,
      handleHideExpandedButton,
    ],
  );

  return (
    <ToolbarContext.Provider value={value}>{children}</ToolbarContext.Provider>
  );
}

ToolbarProvider.ExpandedToolbar = ExpandedToolbar;
ToolbarProvider.MiniToolbar = DesktopMiniToolbar;
ToolbarProvider.MobileToolbar = MobileToolbar;
ToolbarProvider.Content = Content;
