import isEmbedded from '@lib/loaderHelper';
import AppStatus from '@pages/app-status';
import AssistantsPage from '@pages/assistants';
import AuditPage from '@pages/audit';
import CommandDesignerPage from '@pages/command-designer';
import CommandsPage from '@pages/commands';
import ConnectorsPage from '@pages/connectors';
import ErrorPage from '@pages/error';
import GetTheAppPage from '@pages/get-the-app';
import HistoryPage from '@pages/history';
import HomePage from '@pages/home';
import {
  AssistantsSessionOutlet,
  DashboardOutlet,
  KnowledgeBaseOutlet,
  MainOutlet,
} from '@pages/IndexPage';
import SsoPage from '@pages/sso';
import SsoEditorPage from '@pages/sso-editor';
import SearchPage from '@pages/search';
import SideKickPage from '@pages/side-kick';
import SideKickSessionPage from '@pages/side-kick-session';
import paths from '@router/paths';
import {
  json,
  LoaderFunctionArgs,
  Outlet,
  RouteObject,
} from 'react-router-dom';
import AssistantsSessionPage from '@pages/assistants-session';
import AdminKnowledgeBasePage from '@pages/admin-knowledge-base';
import MemberKnowledgeBasePage from '@pages/member-knowledge-base';
import { action as homeAction } from '@pages/home';

const routes: RouteObject[] = [
  {
    path: '/',
    element: isEmbedded ? <Outlet /> : <DashboardOutlet />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: paths.editCommand.pathname,
        element: <CommandDesignerPage />,
      },
      {
        path: paths.audit.pathname,
        element: <AuditPage />,
      },
      {
        path: paths.status.pathname,
        element: <AppStatus />,
      },
    ],
  },
  {
    path: '/',
    element: <MainOutlet />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: paths.search.pathname,
        element: <SearchPage />,
        loader: async ({ request }: LoaderFunctionArgs) => {
          const url = new URL(request.url).searchParams.get('t');

          if (url == null) {
            return json({ error: 'thread id is required' }, { status: 404 });
          }

          return 'ok';
        },
      },
      {
        path: paths.sideKick.pathname,
        element: <SideKickPage />,
      },
      {
        path: 'side-kick/session',
        element: <SideKickSessionPage />,
      },
      {
        path: paths.connectors.pathname,
        element: <ConnectorsPage />,
      },
      {
        path: paths.commands.pathname,
        element: <CommandsPage />,
      },
      {
        path: paths.history.pathname,
        element: <HistoryPage />,
      },
      {
        path: paths.assistants.pathname,
        element: <AssistantsPage />,
      },
      {
        path: paths.assistantsSession.pathname,
        element: <AssistantsSessionPage />,
      },
      {
        path: paths.sso.pathname,
        element: <SsoPage />,
      },
      {
        path: paths.editSso.pathname,
        element: <SsoEditorPage />,
      },
      {
        path: paths.getTheApp.pathname,
        element: <GetTheAppPage />,
      },
    ],
  },
  {
    path: '/assistants/session',
    element: <AssistantsSessionOutlet />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <AssistantsSessionPage />,
      },
    ],
  },
  {
    path: '/knowledge-base/admin',
    element: <KnowledgeBaseOutlet />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <AdminKnowledgeBasePage />,
        path: paths.AdminKnowledgeBase.pathname,
      },
    ],
  },
  {
    path: '/knowledge-base/member',
    element: <KnowledgeBaseOutlet />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <MemberKnowledgeBasePage />,
        path: paths.MemberKnowledgeBase.pathname,
      },
    ],
  },
];

export default routes;
