import StyledStack from '@components/pages/knowledge-base/components/header/styles';
import useGetFolderByIdLazily from '@components/pages/knowledge-base/hooks/useGetFolderByIdLazily';
import useKnowledgeBaseData from '@components/pages/knowledge-base/hooks/useKnowledgeBaseData';
import { useTranslation } from '@desygner/ui-common-translation';
import useSearchParamsState from '@hooks/useSearchParamsState';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import paths from '@router/paths';
import { FolderType } from '@shared-types/folders';
import { useCallback } from 'react';
import AddIcon from '@mui/icons-material/Add';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import { MinimalFolderType } from '@components/pages/knowledge-base/admin/types';

const BASE_BREADCRUMB: Omit<FolderType, 'id'> = {
  name: 'Knowledge Base',
  path: paths.AdminKnowledgeBase.pathname,
  breadcrumb: 'knowledge base',
  parentId: null, //? root folder
};

export default function Header() {
  const [searchParams, setSearchParams] = useSearchParamsState('path', '');
  const {
    currentFolderId,
    handleCurrentFolderId,
    handleToggleAddKnowledgeModal,
    handleToggleCreateFolderModal,
  } = useKnowledgeBaseData();

  const { folderData } = useGetFolderByIdLazily(currentFolderId);

  const { t } = useTranslation();

  const calculatedBreadcrumbs =
    folderData && folderData.breadcrumb.length > 0
      ? folderData.breadcrumb.split('/')
      : [];

  const handleBreadcrumbs = useCallback(
    ({ folderId, name }: MinimalFolderType) => {
      const arrayOfPaths = searchParams.split('/');
      if (arrayOfPaths.includes(name)) {
        const index = arrayOfPaths.indexOf(name);
        const slicedArray = arrayOfPaths.slice(0, index);
        const concatenatedPath = slicedArray.join('/');
        setSearchParams(concatenatedPath);
      } else {
        setSearchParams('');
      }
      handleCurrentFolderId(folderId);
    },
    [handleCurrentFolderId, searchParams, setSearchParams],
  );

  return (
    <StyledStack>
      <Stack gap={2}>
        <Typography variant="h3">
          {t('page.knowledgeBase.admin.header.title', {
            defaultValue: 'Knowledge Base',
          })}
        </Typography>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            role="button"
            component={Box}
            sx={{
              cursor: 'pointer',
            }}
            color="inherit"
            onClick={() =>
              handleBreadcrumbs({
                folderId: BASE_BREADCRUMB.parentId,
                name: BASE_BREADCRUMB.name,
              })
            }
          >
            {BASE_BREADCRUMB.name}
          </Link>
          {calculatedBreadcrumbs.map((breadcrumb) => (
            <Link
              onClick={() =>
                handleBreadcrumbs({
                  folderId: folderData!.parentId,
                  name: folderData!.name,
                })
              }
              role="button"
              component={Box}
              sx={{
                cursor: 'pointer',
              }}
              color="inherit"
              key={folderData!.id}
            >
              {breadcrumb}
            </Link>
          ))}
        </Breadcrumbs>
      </Stack>
      <Stack direction="row" gap={3}>
        <Button
          variant="outlined"
          startIcon={<CreateNewFolderOutlinedIcon />}
          onClick={() => handleToggleCreateFolderModal()}
        >
          {t('page.knowledgeBase.admin.header.buttons.secondary.label', {
            defaultValue: 'Create a folder',
          })}
        </Button>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => handleToggleAddKnowledgeModal()}
        >
          {t('page.knowledgeBase.admin.header.buttons.primary.label', {
            defaultValue: 'Add knowledge',
          })}
        </Button>
      </Stack>
    </StyledStack>
  );
}
