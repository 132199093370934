import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import { alpha, styled } from '@mui/material';
import Switch from '@mui/material/Switch';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

export const StyledSearchButton = styled(LoadingButton)(({ theme }) => ({
  '&, &:hover': {
    backgroundColor: alpha(theme.palette.neutral.paper, 0.1),
  },
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.common.white
      : theme.palette.common.black,
  border: `1px solid ${
    theme.palette.mode === 'dark'
      ? alpha(theme.palette.common.white, 0.06)
      : alpha(theme.palette.common.black, 0.06)
  }`,
  margin: theme.spacing(1),
  boxSizing: 'border-box',
  height: theme.spacing(10),
  whiteSpace: 'nowrap',
  fontSize: theme.typography.body2.fontSize,
  textTransform: 'none',
  borderRadius: theme.shape.borderRadius * 10,
  minWidth: theme.spacing(42.25),
  [theme.breakpoints.down('md')]: {
    minWidth: theme.spacing(10),
  },
  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(1),
  },
  padding: '0!important',
  '.MuiButton-endIcon': {
    margin: 0,
  },
}));

export const IOSSwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 0,
        ...theme.applyStyles('dark', {
          backgroundColor: theme.palette.primary.main,
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: theme.palette.primary.main,
      border: '6px solid',
      borderColor: theme.palette.common.white,
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
      ...theme.applyStyles('dark', {
        color: theme.palette.grey[600],
      }),
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
      ...theme.applyStyles('dark', {
        opacity: 0.3,
      }),
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.action.disabledBackground,
    }),
  },
}));

export const StyledSearchFormWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  border: '1px solid',
  borderColor: theme.palette.background.card.main,
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  borderRadius: theme.shape.borderRadius * 6,
}));

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
  },
});
