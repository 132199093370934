import { MinimalFolderType } from '@components/pages/knowledge-base/admin/types';
import { folders } from '@lib/agent';
import { useQuery } from '@tanstack/react-query';

export default function useGetFoldersDataByIdLazily(
  folderId: MinimalFolderType['folderId'] = null,
) {
  const {
    data: foldersData,
    isLoading: isFoldersDataLoading,
    isError: isFoldersDataError,
  } = useQuery({
    queryKey: ['folders', folderId],
    queryFn: async () => {
      try {
        const { data } = await folders.getAll({
          parent: folderId,
          workspaceId: '01918c48-915d-729c-abf2-807b79c8d75f', //TODO: we need to make this dynamic,
        });

        return data;
      } catch (error: unknown) {
        throw new Error(`An error occurred while fetching folders: ${error}`);
      }
    },
  });

  return {
    foldersData,
    isFoldersDataLoading,
    isFoldersDataError,
  };
}
