//TODO: there are some static CSS rule sets that need to be dynamic.
import usePromptHistoryState from '@components/pages/hooks/usePromptHistoryState';
import useRedirectHistoryState from '@components/pages/hooks/useRedirectHistoryState';
import Layout from '@components/pages/search/components/shared-blocks/layout';
import LoadingSkeleton from '@components/pages/search/sections/loading-skeleton';
import StreamingAnswer from '@components/pages/search/sections/web-socket-blocks/streaming-answer';
import StreamingFollowups from '@components/pages/search/sections/web-socket-blocks/streaming-followups';
import StreamingInfo from '@components/pages/search/sections/web-socket-blocks/streaming-info';
import StreamingLinks from '@components/pages/search/sections/web-socket-blocks/streaming-links';
import StreamingPhotos from '@components/pages/search/sections/web-socket-blocks/streaming-photos';
import StreamingPrompt from '@components/pages/search/sections/web-socket-blocks/streaming-prompt';
import StreamingSinglePhotosCol from '@components/pages/search/sections/web-socket-blocks/streaming-single-photos-col';
import SearchForm from '@components/search-form';
import useGeolocation from '@hooks/useGeoLocation';
import useGetThreadIdFromQueryParam from '@hooks/useGetThreadFromQueryParam';
import { useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { queryClient } from '@providers/ReactQueryProvider';
import { WSResponseType } from '@state/middleware/types';
import { RootType } from '@state/store';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

type FormType = {
  name: string;
  advanced: boolean; //? Genius Search
};

export default function SearchResult() {
  const { threadId } = useGetThreadIdFromQueryParam();
  const dispatch = useDispatch();
  const { state, clearHistoryStateRedirect } = useRedirectHistoryState();
  const WSMessageStatus = useSelector<RootType, 'started' | 'finished' | null>(
    (state) => state.websocket.WSMessageStatus,
  );
  const WSResponse = useSelector<RootType, WSResponseType>(
    (state) => state.websocket.WSResponse,
  );
  const { historyStatePrompt } = usePromptHistoryState();
  const isWSLoading = useSelector<RootType, boolean>(
    (state) => state.websocket.isWSLoading,
  );
  const askAIErrorStatus = useSelector(
    (state: RootType) => state.searchResult.askAIErrored?.status,
  );
  const advancedSearch = useSelector(
    (state: RootType) => state.searchResult.advancedSearch,
  );
  const theme = useTheme();
  const { locationData, loading } = useGeolocation();

  useEffect(() => {
    if (
      threadId == null ||
      loading ||
      historyStatePrompt == null ||
      !state?.isUserRedirected
    ) {
      return;
    }

    dispatch({
      type: 'searchResult/askAIEmitted',
      payload: {
        threadId,
        advanced: advancedSearch,
        prompt: historyStatePrompt,
        latlng: locationData,
      },
    });
    //? revalidate threads for search in history
    queryClient.invalidateQueries({
      queryKey: ['threads', 'search'],
    });
    clearHistoryStateRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.isUserRedirected, loading, threadId]);

  const handleFetchProgrammatically = useCallback(
    ({ advanced, name }: FormType) => {
      if (locationData == null || threadId == null) return;
      dispatch({
        type: 'searchResult/askAIEmitted',
        payload: {
          threadId,
          prompt: name,
          advanced,
          latlng: locationData,
        },
      });
      window.history.pushState(
        {
          prompt: name,
        },
        '',
        null,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locationData, threadId],
  );

  const SearchBar = useMemo(
    () => (
      <SearchForm
        onSubmit={handleFetchProgrammatically}
        sx={{
          position: 'fixed',
          zIndex: theme.zIndex.fab,
          margin: {
            xs: theme.spacing(0, 2),
            md: theme.spacing(0, '12%'),
          },
          width: {
            md: theme.spacing(132.5),
          },
          bottom: {
            xs: theme.spacing(22.5),
            md: theme.spacing(5),
          },
          left: {
            xs: 0,
            md: 'inherit',
          },
          right: {
            xs: 0,
            md: 'inherit',
          },
        }}
      />
    ),
    [handleFetchProgrammatically, theme],
  );

  if (askAIErrorStatus != null) {
    return SearchBar;
  }

  if (WSResponse.searchResult.TOS != null) {
    return (
      <>
        {SearchBar}
        <Grid container>
          <Grid item md={12}>
            <Card>
              <Typography variant="h4">
                {WSResponse.searchResult.TOS}
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  }

  if (isWSLoading) {
    return <LoadingSkeleton />;
  }

  if (WSMessageStatus == null) {
    return SearchBar;
  }

  return (
    <Layout
      type="web-socket"
      answer={<StreamingAnswer />}
      info={<StreamingInfo />}
      followups={<StreamingFollowups />}
      prompt={<StreamingPrompt />}
      links={<StreamingLinks />}
      photos={<StreamingPhotos />}
      singlePhotosCol={<StreamingSinglePhotosCol />}
      searchForm={SearchBar}
    />
  );
}
