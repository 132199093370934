import paths from '@router/paths';
import HistoryIcon from '@mui/icons-material/History';
import SearchIcon from '@mui/icons-material/Search';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import KeyboardCommandKeyIcon from '@mui/icons-material/KeyboardCommandKey';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import HubIcon from '@mui/icons-material/Hub';

const DELAY_HIDE_EXPANDED_BUTTON = 1500;

const TOOLBAR_ITEMS = [
  {
    isAlpha: false,
    mappedLabel: 'layout.toolbar.buttons.search',
    defaultLabel: 'Search',
    icon: <SearchIcon />,
    url: paths.home.pathname,
  },
  {
    isAlpha: false,
    mappedLabel: 'layout.toolbar.buttons.sideKick',
    defaultLabel: 'Side-kick',
    icon: <SentimentSatisfiedAltIcon />,
    url: paths.sideKick.pathname,
  },
  {
    isAlpha: false,
    mappedLabel: 'layout.toolbar.buttons.history',
    defaultLabel: 'History',
    icon: <HistoryIcon />,
    url: paths.history.pathname,
  },
  {
    isAlpha: true,
    mappedLabel: 'layout.toolbar.buttons.commands',
    defaultLabel: 'Commands',
    icon: <KeyboardCommandKeyIcon />,
    url: paths.commands.pathname,
  },
  {
    isAlpha: true,
    mappedLabel: 'layout.toolbar.buttons.connectors',
    defaultLabel: 'Connectors',
    icon: <HubIcon />,
    url: paths.connectors.pathname,
  },
  {
    isAlpha: false,
    mappedLabel: 'layout.toolbar.buttons.assistants',
    defaultLabel: 'Assistants',
    icon: <SmartToyOutlinedIcon />,
    url: paths.assistants.pathname,
  },
];

const CONFIG = {
  PATH_TO_SHOW_COLLAPSING_BUTTON: 20,
  TOOLBAR_MINI: {
    DESKTOP: {
      WIDTH: 52,
    },
    MOBILE: {
      HEIGHT: 81,
    },
  },
  CREDIT_USAGE: {
    DESKTOP: {
      WIDTH: 44,
    },
    MOBILE: {
      HEIGHT: 44,
    },
  },
  WIDTH_EXPANDED: 212,
  TOOLBAR_ITEMS,
  DELAY_HIDE_EXPANDED_BUTTON,
} as const;

export default CONFIG;
