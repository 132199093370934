/* eslint-disable no-case-declarations */
import SocketFactory, { SocketInterface } from '@lib/socketFactory';
import { StreamingType as AssistantStreamingType } from '@shared-types/assistants';
import {
  StreamingType as SearchResultStreamingType,
  StreamingUpdateType,
} from '@shared-types/search-result/streaming';
import {
  AnswerType,
  InfoType,
  LinksType,
  PhotosType,
} from '@shared-types/search-result/types';
import { WSEvents, WSResponseType } from '@state/middleware/types';
import {
  reset,
  WSConnected,
  WSDisconnected,
  WSErrored,
  WSLoaded,
  WSMessageStatusUpdated,
  WSResponseUpdated,
} from '@state/slices/web-socket';
import { RootType } from '@state/store';
import { produce } from 'immer';
import { isEqual, throttle } from 'lodash';
import { Action, Middleware } from 'redux';
import { reset as userReset } from '@state/slices/assistant-result';

const THROTTLED_INTERVAL_IN_MS = 200;
export let socketInstance: SocketInterface;

const socketMiddleware: Middleware = ({ dispatch, getState }) => {
  //TODO: currentWSResponse should be reset for assistant result as well
  let currentWSResponse = {
    searchResult: {
      layout: [],
      followups: [],
      TOS: null,
    },
    assistant: {
      composed: [],
      text: '',
    },
  };

  const throttledDispatch = throttle((websocketResult) => {
    const prevWSResponse = (getState() as RootType).websocket.WSResponse;

    //? a naive solution to avoid re-rendering the same data in the UI + throttling for now
    if (!isEqual(prevWSResponse, currentWSResponse)) {
      dispatch(WSResponseUpdated(websocketResult));
    }
  }, THROTTLED_INTERVAL_IN_MS);

  return (next) => (action) => {
    const { type } = action as Action<'socket/init' | 'socket/disconnect'>;

    switch (type) {
      case 'socket/init':
        if (socketInstance) return;

        socketInstance = SocketFactory.create();
        socketInstance.socket.connect();

        socketInstance.socket.on(WSEvents.CONNECTED, () => {
          dispatch(WSConnected());
        });

        socketInstance.socket.on(WSEvents.ERROR, (message) => {
          console.error('🚫Socket Error🚫', message);
          dispatch(WSErrored(message));
        });

        socketInstance.socket.on(WSEvents.MESSAGE, (message) => {
          currentWSResponse = transformMessage(message, currentWSResponse); //TODO: type of currentWSResponse will be fixed after understanding the message kind

          if (message.type === 'status' && message.response === 'finished') {
            dispatch(WSResponseUpdated(currentWSResponse)); //? making sure the last message is dispatched since the throttling might cause the last message to be missed
            dispatch(WSMessageStatusUpdated('finished'));
            dispatch({ type: 'searchResult/streaming/finished' });
            dispatch({ type: 'assistantResult/streaming/finished' });

            //? delay the execution of the state reset logic so we ensure that the data is stored in the cache first
            //! later on we need to refactor this to store the data by threadId
            setTimeout(() => {
              dispatch(reset());
              dispatch(userReset());
              currentWSResponse = {
                searchResult: {
                  layout: [],
                  followups: [],
                  TOS: null,
                },
                assistant: {
                  composed: [],
                  text: '',
                },
              };
            }, 0);
          }

          if (message.type === 'status' && message.response === 'started') {
            dispatch(WSMessageStatusUpdated('started'));
          }

          //? postpone the WSLoaded action until the layout is received
          if (message.type === 'layout') {
            dispatch(WSLoaded(false));
          }

          //? if the stream_counter is greater than 0, it means the streaming has started this is how we check for assistants
          if (message.stream_counter > 0) {
            dispatch(WSMessageStatusUpdated('started'));
            dispatch(WSLoaded(false));
          }

          throttledDispatch(currentWSResponse);
        });
        break;
      case 'socket/disconnect':
        socketInstance.socket.on(WSEvents.DISCONNECTED, (reason) => {
          console.error('🚫Socket Disconnected🚫', reason);
          dispatch(WSDisconnected());
        });
        break;
    }

    next(action);
  };
};

export default socketMiddleware;

function transformMessage(
  message: SearchResultStreamingType | AssistantStreamingType,
  currentWSResponse: WSResponseType,
) {
  if ('type' in message) {
    // it is a SearchResultStreamingType
    return produce(currentWSResponse, (draft) => {
      switch (message.type) {
        case 'layout':
          if (message.layout.answer) {
            draft.searchResult.layout.push({
              type: 'answer',
              ...message.layout.answer,
              text: '',
              composed: [],
            });
          }
          if (message.layout.info && message.layout.info.length > 0) {
            draft.searchResult.layout.push({
              ...message.layout.info[0],
              text: '',
              composed: [],
              type: 'info',
            });
          }
          if (message.layout.linkId) {
            draft.searchResult.layout.push({
              id: message.layout.linkId,
              links: [],
              type: 'links',
            });
          }
          if (message.layout.photos) {
            draft.searchResult.layout.push({
              ...message.layout.photos,
              photos: [],
              type: 'photos',
            });
          }
          break;
        case 'followup':
          draft.searchResult.followups = message.followups;
          break;
        case 'update':
          return updateLayoutResponse(message, currentWSResponse);
        case 'tos':
          draft.searchResult.TOS = message.response;
          break;
        default:
          return draft;
      }
    });
  }

  // it is an AssistantStreamingType
  if (!('type' in message)) {
    return produce(currentWSResponse, (draft) => {
      if (typeof message.stream_counter !== 'number') return;
      draft.assistant.composed[message.stream_counter] = message.text;
      draft.assistant.text = draft.assistant.composed.join('');
      return draft;
    });
  }
}

function updateLayoutResponse(
  message: StreamingUpdateType,
  currWSResponse: WSResponseType,
) {
  return produce(currWSResponse, (draft) => {
    switch (message.componentType) {
      case 'photos':
        const photosElement = draft.searchResult.layout.find(
          (element) => element?.id === message.id,
        ) as PhotosType | undefined;

        if (photosElement) {
          photosElement.photos = message.photos;
        }
        break;
      case 'answer':
      case 'info':
        const answerOrInfoElement = draft.searchResult.layout.find(
          (element) => element?.id === message.id,
        ) as InfoType | AnswerType | undefined;

        if (answerOrInfoElement) {
          if (typeof message.stream_counter !== 'number') return;
          answerOrInfoElement.composed[message.stream_counter] = message.text;
          answerOrInfoElement.text = answerOrInfoElement.composed.join('');
        }
        break;
      case 'links':
        const linksElement = draft.searchResult.layout.find(
          (element) => element?.id === message.linkId,
        ) as LinksType | undefined;

        if (linksElement) {
          linksElement.links = message.links;
        }
        break;
      default:
        return draft;
    }
  });
}
