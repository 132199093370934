import { alpha, styled } from '@mui/material';
import Button from '@mui/material/ButtonBase';

const StyledFeatureButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  color: '#E3C4EE',
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha('#E3C4EE', theme.palette.action.hoverOpacity),
}));

export default StyledFeatureButton;
