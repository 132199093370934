/* eslint-disable import/prefer-default-export */
import requests, { responseBody } from '@lib/agent/requests';
import axiosApiInstance from '@lib/agent/services/api/axiosInstance';
import axiosAwsInstance from '@lib/agent/services/aws/axiosInstance';
import {
  PolicyType,
  CredentialsType,
  FileUploadType,
  PolicyRequestType,
} from '@shared-types/storage';

export const storage = {
  createPolicy: <T>(body: PolicyRequestType<T>) =>
    requests(axiosApiInstance).post<PolicyType>(
      '/v1/actions/storage/policy',
      body,
    ),
  getCredentials: (s3Key: string) =>
    requests(axiosApiInstance).get<CredentialsType>(
      `/v1/actions/storage/credentials?key=${s3Key}`,
    ),
  uploadFile: ({ url, contentType, body, config }: FileUploadType) =>
    axiosAwsInstance
      .put<any>(url, body, {
        headers: { 'Content-Type': contentType },
        ...config,
      })
      .then(responseBody),
};
