import useGetFolderPathFromQueryParam from '@components/pages/knowledge-base/hooks/useGetFolderPathFromQueryParam';
import CONFIG from '@components/uppy-upload/config';
import {
  StyledDashboard,
  StyledDragDrop,
} from '@components/uppy-upload/styles';
import { useTranslation } from '@desygner/ui-common-translation';
import useGetFolderDataByPathLazily from '@hooks/useGetFolderDataByPathLazily';
import { storage } from '@lib/agent';
import { convertToRem } from '@lib/fonts';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { queryClient } from '@providers/ReactQueryProvider';
import { FolderType } from '@shared-types/folders';
import { SourceType } from '@shared-types/sources';
import AwsS3 from '@uppy/aws-s3';
import { Uppy } from '@uppy/core';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/drag-drop/dist/style.css';
import RemoteSources from '@uppy/remote-sources';
import { useState } from 'react';
import { toast } from 'react-toastify';

type ContextType =
  | {
      entity_type: string;
      entity_id?: number;
      usage: string;
    }
  | undefined;

const DRAG_DROP_HEIGHT = 450;
const DASHBOARD_HEIGHT = 250;

function createUppyInstance(folder: FolderType | null) {
  return new Uppy({
    debug: true,
    restrictions: {
      allowedFileTypes: [...CONFIG.FILE_TYPES],
    },
  })
    .use(RemoteSources, {
      companionUrl: import.meta.env.VITE_COMPANION_URL,
    })
    .use(AwsS3, {
      getUploadParameters: async (file) => {
        const name = file.data.name;
        const size = file.data.size;
        const type = file.data.type;
        const { data } = await storage.createPolicy<ContextType>({
          key: name,
          size,
          type,
          context:
            folder && folder.id
              ? {
                  entity_type: 'folder',
                  entity_id: folder.id,
                  usage: 'source',
                }
              : undefined, //TODO: conditionally add context since when we are outside of the folder we do not have the folder id
        });

        return {
          method: 'PUT',
          fields: [],
          headers: {
            'Content-Type': type,
          },
          url: data.url,
        };
      },
    });
}

export default function UppyUpload() {
  const path = useGetFolderPathFromQueryParam();
  const { t } = useTranslation();
  const { folderData, isFolderDataLoading } =
    useGetFolderDataByPathLazily(path);

  const [uppy] = useState<Uppy | null>(
    createUppyInstance(folderData ? folderData : null).on(
      'complete',
      (result) => {
        toast.success(
          t('response.success.uploadedSource', {
            defaultValue: 'You have successfully uploaded a source.',
          }),
        );
        result.successful?.forEach((item) => {
          //? optimistic update since we do not get the source data right after upload from the server
          queryClient.cancelQueries({
            queryKey: ['sources', folderData?.id],
          });
          const lastSources = queryClient.getQueryData<SourceType[] | null>([
            'sources',
            folderData?.id,
          ]);
          if (lastSources == null) return;

          queryClient.setQueryData(
            ['sources', folderData?.id],
            (): Partial<
              Pick<SourceType, 'name' | 'folderId' | 'fileType'>
            >[] => [
              ...lastSources,
              {
                fileType: item.extension,
                ...(folderData && { folderId: folderData.id }), //? check whether folderData is not null so then we are on the root folder and we are not in a folder
                name: item.name as string,
              },
            ],
          );
        });
      },
    ),
  );

  return isFolderDataLoading ? (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: convertToRem(DASHBOARD_HEIGHT + DRAG_DROP_HEIGHT),
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <>
      <StyledDragDrop
        note={t('page.knowledgeBase.upload.note', {
          defaultValue: 'audio, text and docs, up to 5GB',
        })}
        uppy={uppy as never}
        allowMultipleFiles
        locale={
          {
            strings: {
              dropHereOr: 'Drop files here or %{browse}',
              browse: t('page.knowledgeBase.upload.browseFiles', {
                defaultValue: 'browse files',
              }),
            },
          } as never
        }
        width="100%"
        height={DRAG_DROP_HEIGHT}
      />
      <StyledDashboard
        disableLocalFiles={true}
        uppy={uppy as never}
        proudlyDisplayPoweredByUppy={false}
        width="100%"
        height={DASHBOARD_HEIGHT}
      />
    </>
  );
}
