import { useTranslation } from '@desygner/ui-common-translation';
import { auth } from '@lib/agent';
import { SignUpBodyType } from '@shared-types/auth';
import { useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';
import { signUp } from '@lib/auth/recaptcha';
import { setSession } from '@lib/jwt';
import useSearchParamsState from '@hooks/useSearchParamsState';
import useAuth from '@hooks/useAuth';

const promotiveSignUp = async (data: SignUpBodyType) => {
  let token = window.localStorage.getItem('token');
  if (!token) {
    const grecaptcha = window.localStorage.getItem('_grecaptcha');

    if (!grecaptcha) {
      window.location.reload();
    }

    const response = await signUp(grecaptcha!);

    if (response?.data.status === 'Ok') {
      token = response?.data.token;
    }
  }

  return await auth.promotiveSignUp(data, token!);
};

export default function usePromotiveSignUp() {
  const { t } = useTranslation();
  const [, setSearchParams] = useSearchParamsState('action', '');
  const { handleUpdateMe } = useAuth();

  return useMutation({
    mutationFn: promotiveSignUp,
    onError: (error) => {
      if (isAxiosError(error) && error.response?.status === 409) {
        toast.error(
          t('response.errors.code.409', {
            defaultValue: 'User already exists. Please try to login.',
          }),
        );
        return;
      }
      toast.error(
        t('response.errors.code.520', {
          defaultValue: 'Something went wrong. Please try again later.',
        }),
      );
      console.error(error);
    },
    onSuccess({ data: { token } }) {
      window.localStorage.removeItem('fingerPrintId');
      setSession(token);
      handleUpdateMe(token);
      toast.success(
        t('response.success.signUp', {
          defaultValue: 'Your account created successfully.',
        }),
      );
      setSearchParams('');
    },
  });
}
